<template>
  <div class="my-3">
    <v-row class="px-3">
      <v-btn
        v-if="$can('create', 'PartnerApiKey')"
        color="primary"
        dark
        class="mb-1 mr-3"
        :loading="modalVisible"
        :disabled="modalVisible"
        @click="showModal"
      >
        Add new
      </v-btn>
      <v-btn
        color="info"
        outlined
        dark
        class="mb-1"
        target="_blank"
        :href="$endpointService.docs"
      >
        View API Docs
      </v-btn>
      <v-spacer></v-spacer>
      <div>
        <v-text-field
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          dense
          outlined
          clearable
          maxlength="150"
        ></v-text-field>
      </div>
    </v-row>
    <partner-api-keys-table
      ref="partnerApiKeysTable"
      :search="search"
      @selectItem="handleSelect"
    />
    <partner-api-key-dialog
      v-if="modalVisible"
      :show.sync="modalVisible"
      :item.sync="selectedItem"
      @onSave="$refs.partnerApiKeysTable.getItems()"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'
import PartnerApiKeysTable from '@/views/components/partner-api-keys/PartnerApiKeysTable.vue'
import PartnerApiKeyDialog from '@/views/components/partner-api-keys/PartnerApiKeyDialog.vue'

export default {
  components: {
    PartnerApiKeysTable,
    PartnerApiKeyDialog,
  },

  setup() {
    const search = ref('')
    const loading = ref(false)
    const modalVisible = ref(false)

    const selectedItem = ref(null)

    const showModal = () => {
      modalVisible.value = true
    }

    const handleSelect = item => {
      selectedItem.value = item
      showModal()
    }

    return {
      icons: {
        mdiMagnify,
      },

      search,
      loading,

      selectedItem,

      showModal,
      handleSelect,

      modalVisible,
    }
  },
}
</script>
