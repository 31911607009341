<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead class="position-relative">
          <tr>
            <th
              class="text-uppercase"
              @click="changeOrder('ID')"
            >
              ID
              <sorting-arrows :direction="column === 'ID' ? order : null" />
            </th>
            <th
              class="text-uppercase"
              @click="changeOrder('NAME')"
            >
              Name
              <sorting-arrows :direction="column === 'NAME' ? order : null" />
            </th>
            <th
              class="text-uppercase"
              @click="changeOrder('API_KEY')"
            >
              Key
              <sorting-arrows :direction="column === 'API_KEY' ? order : null" />
            </th>
            <th
              class="text-uppercase"
              @click="changeOrder('IP_ADDRESS')"
            >
              IP Address
              <sorting-arrows :direction="column === 'IP_ADDRESS' ? order : null" />
            </th>
            <th
              class="text-uppercase"
              @click="changeOrder('ACCESSED_AT')"
            >
              Last Accessed At
              <sorting-arrows :direction="column === 'ACCESSED_AT' ? order : null" />
            </th>
            <th
              class="text-uppercase"
              @click="changeOrder('USAGE_COUNT')"
            >
              Usage Count
              <sorting-arrows :direction="column === 'USAGE_COUNT' ? order : null" />
            </th>
            <th class="text-uppercase">
              Actions
            </th>
          </tr>

          <v-progress-linear
            bottom
            absolute
            indeterminate
            :active="$apollo.queries.partnerApiKeys.loading"
          ></v-progress-linear>
        </thead>
        <tbody class="position-relative">
          <tr v-if="items.length == 0">
            <td
              colspan="7"
              class="text-center my-5"
            >
              <h3>
                No items
              </h3>
            </td>
          </tr>
          <tr
            v-for="item in items"
            v-else
            :key="item.id"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.api_key }}</td>
            <td>{{ item.ip_address }}</td>
            <td>{{ item.accessed_at }}</td>
            <td>{{ item.usage_count }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    icon
                    small
                    @click="handleCopy(item)"
                    v-on="on"
                  >
                    <v-icon size="20">
                      {{ icons.mdiContentCopy }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Copy API Key</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="$can('update', 'PartnerApiKey')"
                    v-bind="attrs"
                    icon
                    small
                    @click="handleEdit(item)"
                    v-on="on"
                  >
                    <v-icon size="20">
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="$can('destroy', 'PartnerApiKey')"
                    v-bind="attrs"
                    icon
                    small
                    @click="handleDelete(item)"
                    v-on="on"
                  >
                    <v-icon size="20">
                      {{ icons.mdiTrashCanOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <pagination
      v-if="items"
      :count="countPages"
      :page.sync="currentPage"
    />
    <delete-partner-api-key-dialog
      v-if="showDeleteModal && $can('destroy', 'PartnerApiKey')"
      :show.sync="showDeleteModal"
      :item.sync="selectedItem"
      title="Delete Api Key"
      @success="getItems()"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { getCurrentInstance, ref } from '@vue/composition-api'
import { mdiContentCopy, mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js'
import Pagination from '@/views/components/pagination/Pagination.vue'
import gql from 'graphql-tag'
import DeletePartnerApiKeyDialog from '@/views/components/partner-api-keys/DeletePartnerApiKeyDialog.vue'
import SortingArrows from '../SortingArrows.vue'

export default {
  name: 'AdminsTable',
  emits: [ 'selectItem' ],
  components: {
    Pagination,
    DeletePartnerApiKeyDialog,
    SortingArrows,
  },
  props: {
    search: {
      required: false,
      default: null,
      type: String,
    },
  },
  apollo: {
    partnerApiKeys: {
      query: gql`
        query ($page: Int!, $first: Int, $search: String, $orderBy: [QueryPartnerApiKeysOrderByOrderByClause!]) {
          partnerApiKeys(page: $page, first: $first, search: $search, orderBy: $orderBy) {
            data {
              id
              name
              api_key
              ip_address
              usage_count
              accessed_at
              created_at
              updated_at
            }
            paginatorInfo {
              currentPage
              lastPage
            }
          }
        }
      `,
      debounce: 150,
      errorPolicy: 'all',
      variables() {
        return {
          search: this.search,
          page: this.currentPage,
          first: 10,
          orderBy: [{
            column: this.column ?? 'ID',
            order: this.order ?? 'ASC',
          }],
        }
      },
      result(res) {
        if (res.error) return

        this.items = res.data.partnerApiKeys.data || []
        this.countPages = res.data.partnerApiKeys.paginatorInfo.lastPage
        this.currentPage = res.data.partnerApiKeys.paginatorInfo.currentPage
      },
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy

    const column = ref('ID')
    const order = ref('ASC')

    const countPages = ref(1)
    const currentPage = ref(1)

    const items = ref([])

    const selectedItem = ref(null)
    const showDeleteModal = ref(false)

    const handleEdit = item => {
      selectedItem.value = item
      emit('selectItem', item)
    }

    const handleDelete = item => {
      selectedItem.value = item
      showDeleteModal.value = true
    }

    const getItems = () => vm.$apollo.queries.partnerApiKeys.refetch()

    const changeOrder = col => {
      column.value = col
      order.value = order.value === 'ASC' ? 'DESC' : 'ASC'
    }

    const handleCopy = async item => {
      try {
        await navigator.clipboard.writeText(item.api_key);
        vm.$toastr.i('Copied to clipboard.')
      } catch($e) {
        vm.$toastr.e('Unable to copy to clipboard.')
      }
    }

    return {
      icons: {
        mdiContentCopy,
        mdiPencilOutline,
        mdiTrashCanOutline,
      },
      items,
      currentPage,
      countPages,
      selectedItem,
      showDeleteModal,
      column,
      order,

      handleCopy,
      handleEdit,
      handleDelete,

      getItems,
      changeOrder,
    }
  },
}
</script>
