<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ title }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="partnerName"
                dense
                label="Name"
                type="text"
                name="name"
                autofocus
                autocomplete="off"
                :error-messages="errorMessages.name"
                hide-details="auto"
              ></v-text-field>
              <div
                v-if="!$v.partnerName.required"
                class="validationError pt-1"
              >
                Please enter valid name.
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="closeModal"
        >
          Close
        </v-btn>
        <v-btn
          v-if="$can(ability, 'PartnerApiKey')"
          color="success"
          :disabled="$v.$invalid || loading"
          :loading="loading"
          @click="handleSave"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
import { computed, onMounted, ref, getCurrentInstance } from '@vue/composition-api'
import { required } from 'vuelidate/lib/validators'
import gql from 'graphql-tag'

export default {
  name: 'PartnerApiKeyDialog',
  emits: [ 'onSave', 'onCancel' ],
  props: {
    item: {
      type: Object,
      required: false,
      default: null,
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy

    const loading = ref(false)

    const isDialogVisible = ref(props.show)
    const errorMessages = ref([])

    const partnerName = ref('')

    const title = computed(() => {
      if(props.item === null)
        return 'New Partner API'

      return 'Edit Partner Entry'
    })

    const ability = computed(() => props.item === null ? 'create' : 'update')

    const closeModal = () => {
      isDialogVisible.value = false
      emit('update:show', false)
      emit('update:item', null)
    }

    onMounted(() => {
      if (props.item !== null) {
        partnerName.value = props.item.name
      }
    })

    const handleSave = async () => {
      loading.value = true

      try {
        if (props.item !== null) {
          await vm.$apollo.mutate({
            mutation: gql`
              mutation ($partnerApiKey: PartnerApiKeyInput!) {
                updatePartnerApiKey (partnerApiKey: $partnerApiKey) {
                  id
                  name
                  api_key
                  ip_address
                  usage_count
                  accessed_at
                }
              }
            `,
            variables: {
              partnerApiKey: {
                id: props.item.id,
                name: partnerName.value
              }
            }
          })
          vm.$toastr.s('Partner successfully updated!')
        } else {
          await vm.$apollo.mutate({
            mutation: gql`
              mutation ($partnerApiKey: PartnerApiKeyInput!) {
                createPartnerApiKey (partnerApiKey: $partnerApiKey) {
                  id
                  name
                  api_key
                  ip_address
                  usage_count
                  accessed_at
                }
              }
            `,
            variables: {
              partnerApiKey: {
                name: partnerName.value
              }
            }
          })
          vm.$toastr.s('Partner successfully added!')
        }

        emit('onSave')

      } catch (err) {
        console.error(err)
      } finally {
        loading.value = false

        emit('update:item', null)
        emit('update:show', false)

        isDialogVisible.value = false
      }
    }


    return {
      isDialogVisible,
      closeModal,

      errorMessages,

      partnerName,

      loading,
      handleSave,

      title,
      ability,
    }
  },

  validations: {
    partnerName: {
      required,
    },
  },

  methods: {
    getPermissionLabel(permission) {
      return permission[0].toUpperCase() + permission.slice(1)
    },
  },
}
</script>
